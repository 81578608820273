import { initClickableArea, tabHighlight, tabHighlightRemove } from "./components/global_accessibility";
//import { setCookie, deleteCookie, dbyh_ajax_call, initLightbox } from "./components/global_functions";
// import { labels } from "./localization";

export default {
	init() {
		// JavaScript to be fired on all pages

		// Accessibility: Handle tab highlighting
		//---------------------------------------------------------------------------
		$("body").on("keyup", "a, .btn, button, input, textarea, select, .clickable-area", function (e) {
			tabHighlight(e);
		});

		$("body").on("blur", ".tabbed", function (e) {
			tabHighlightRemove(e);
		});

		// Accessibility: Initiate Clickable areas
		//---------------------------------------------------------------------------
		initClickableArea();

		// Accessibility: Skip Navigation on first tab
		//---------------------------------------------------------------------------
		document.querySelectorAll("#skipnav p a").forEach(function (trigger) {
			trigger.addEventListener("focus", function (event) {
				document.body.classList.add("-skip-link");
			});
			trigger.addEventListener("blur", function (event) {
				document.body.classList.remove("-skip-link");
			});
		});

		// Toggle Attribute on all buttons with classname .expander
		//---------------------------------------------------------------------------
		$("button.expander").on("click", function () {
			if ($(this).attr("aria-expanded") === "false") {
				$(this).attr("aria-expanded", true);
			} else {
				$(this).attr("aria-expanded", false);
			}
		});

		// Responsive menu (Main)
		//---------------------------------------------------------------------------
		$("#toggle_navigation").on("click", function () {
			$(this).toggleClass("is-open");
			$(".side-nav").toggleClass("is-open");
			$(".menu-overlay").toggleClass("visible");
		});

		//Toggle with close button when open.
		$(".close-button").on("click", () => {
			$(".side-nav").toggleClass("is-open");
			$(".menu-overlay").toggleClass("visible");
		});

		//Toggle with menu overlay when open.
		$(".menu-overlay").on("click", () => {
			$(".side-nav").toggleClass("is-open");
			$(".menu-overlay").toggleClass("visible");
		});

		// Show submenu, on click
		//---------------------------------------------------------------------------
		$("button.menu-expand").on("click", function () {
			$(this).toggleClass("is-active");
			$(this).parent("li").children(".submenu").slideToggle(150);
		});

		// Open submenu if page is active
		const $ExtraMenu = $("#burger_navigation");

		$ExtraMenu.find("li.current-menu-item, li.current-menu-ancestor, li.current-menu-parent").each(function () {
			$(this).find(".submenu-level-0").slideDown(150);
			$(this).find("button.menu-expand").first().addClass("is-active").attr("aria-expanded", true);

			// find next level
			if ($(this).find(".submenu-level-1")) {
				let $level1SubmenuItems = $(this).find(
					".submenu-level-1 > li.current-menu-item, .submenu-level-1 > li.current_page_item",
				);

				if ($level1SubmenuItems.length != 0) {
					$level1SubmenuItems
						.closest(".submenu")
						.slideDown(150)
						.closest("li.current-menu-parent")
						.find("button")
						.addClass("is-active")
						.attr("aria-expanded", true);
				}
			}
		});

		// Lägg på classer på meny för att docka den vid scroll.
		//---------------------------------------------------------------------------
		$(window).scroll(function () {
			if ($(this).scrollTop() > 50) {
				$("#header").addClass("fixed");
			} else {
				$("#header").removeClass("fixed");
			}
		});

		// Open Search
		//---------------------------------------------------------------------------
		$("#search-button").on("click", function () {
			$("#search-button").attr("aria-expanded", true);

			$(".search-container").toggleClass("open-search");
			$(".header-overlay").toggleClass("open-overlay");

			$(".search-form-field").focus();
		});

		// Close Search
		//---------------------------------------------------------------------------
		$(".search-close").on("click", function () {
			$(".search-container").toggleClass("open-search");
			$(".header-overlay").toggleClass("open-overlay");
			$("#search-button").attr("aria-expanded", false);
		});

		// Initiate Lightbox functions
		//---------------------------------------------------------------------------
		// initLightbox();

		// AJAX function
		//---------------------------------------------------------------------------
		// $(".ajax-call-button").on("click", function() {
		//  dbyh_ajax_call();
		// });

		// Open / Close the adminbar panel
		//---------------------------------------------------------------------------
		const $AdminbarToggle = $("#dbyh-adminbar__toggle"),
			$Adminbar = $("#dbyh-adminbar"),
			$AdminbarSubmenu = $Adminbar.find(".item__submenu"),
			$AdminbarPusher = $(".adminbar-pusher");

		$AdminbarToggle.on("click", function () {
			$AdminbarToggle.toggleClass("is-active");
			$Adminbar.toggleClass("is-open");
			$AdminbarSubmenu.hide();
		});

		$Adminbar.find("li.has-submenu span").on("click", function () {
			$(this).closest("li.has-submenu").find(".item__submenu").slideToggle(200);
		});

		$AdminbarPusher.on("click", function (event) {
			if (event.target.classList.contains("adminbar-pusher")) {
				$AdminbarToggle.removeClass("is-active");
				$Adminbar.removeClass("is-open");
			}
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
