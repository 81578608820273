//---------------------------------------------------------------------------------------------
// Accessibility
//---------------------------------------------------------------------------------------------
/**
 * While tabbing trough elements, add "tabbed" class to element
 * Use: import { tabHighlight } from "./components/global_accessibility";
 */
export function tabHighlight(e) {
	let $target = e.target;
	if (e.which === 9) {
		$($target).addClass("tabbed");
		$($target).closest(".clickable-area").addClass("tabbed");
	}
}

/**
 * While tabbing trough elements, remove "tabbed" class from element
 * Use: import { tabHighlightRemove } from "./components/global_accessibility";
 */
export function tabHighlightRemove(e) {
	let $target = e.target;

	$($target).removeClass("tabbed");
	$($target).closest(".clickable-area").removeClass("tabbed");
}

/**
 * Make whole area clickable.
 * Just add the class "clickable-area" on the element
 * Use: import { initClickableArea } from "./components/global_accessibility";
 */
export function initClickableArea() {
	$(".clickable-area").on("click", function () {
		this.target = $(this);
		return (
			$(this.target).find("a").attr("href").length > 1 &&
				($(this.target).find("a").attr("target")
					? window.open($(this.target).find("a").attr("href"), $(this.target).find("a").attr("target"))
					: (window.location.href = $(this.target).find("a").attr("href"))),
			!1
		);
	});
}